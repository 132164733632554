<template>
  <div>
    <div class="d-flex justify-content-between">
      <div class="input-search mb-4">
        <b-form-input
          autocomplete="off"
          @input="handleSearch"
          v-model="search"
          placeholder="Digite para pesquisar"
          class="col-3 mb-2"
        />
        <b-button
          variant="light"
          class="px-2 d-flex align-items-center absolute icon-action"
        >
          <b-icon icon="search"></b-icon>
        </b-button>
      </div>
      <b-button
        variant="secondary"
        @click="handleAddNewQuestion()"
        class="btn-add"
      >
        Adicionar
      </b-button>
    </div>

    <empty-list
      v-if="emptyQuestions && !isAdd"
      text="Não há perguntas cadastradas"
    />
    <cards-list grid="4" gap="8">
      <b-card
        v-for="(item, index) in items"
        :key="index"
        class="card-hoverable pointer"
      >
        <router-link :to="`/system/satisfaction-survey/questions/${item.id}`">
          <div class="d-flex align-items-center justify-content-between mb-2">
            <strong class="d-block w-100">
              {{ item.name }}
            </strong>
          </div>
          <section>
            <small class="mb-3 d-block">
              {{ item.description }}
            </small>
            <small class="mb-3 d-block">
              {{ item.questions.length }} Perguntas
            </small>
          </section>
        </router-link>
      </b-card>
    </cards-list>
    <b-modal
      id="add-form-questions"
      centered
      hide-footer
      size="md"
      no-close-on-backdrop
      :title="`Formulário de pesquisa`"
    >
      <FormEdit isAdd @submited="handleCloseModal" />
    </b-modal>
    <b-pagination
      v-if="total > 1"
      @change="handleChangePage"
      v-model="current_page"
      :total-rows="total"
      :per-page="per_page"
      aria-controls="my-table"
    />
  </div>
</template>
<script>
import { listPaginated } from "@/mixins/listPaginated";
import { mapActions } from "vuex";
import FormEdit from "./FormEdit";

export default {
  mixins: [listPaginated],

  components: {
    FormEdit,
  },

  data() {
    return {
      action_name: "form",
      params: {},
      selectedId: null,
      isAdd: false,
      emptyQuestions: false,
    };
  },

  methods: {
    ...mapActions(["set_is_preview"]),
    handleAddNewQuestion() {
      this.isAdd = true;
      this.$bvModal.show("add-form-questions");
    },
    handleEditForm(id) {
      this.isAdd = false;
      this.selectedId = id;
      this.$bvModal.show("add-form-questions");
    },
    handleCloseModal() {
      this.$bvModal.hide("add-form-questions");
      this.getList();
    },
  },
  watch: {
    items(e) {
      this.emptyQuestions = e.length === 0;
    },
  },
};
</script>
