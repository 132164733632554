<template>
  <div>
    <b-form @submit.prevent="handleSubmitForm()">
      <b-form-group :label="$t('general.name')">
        <b-form-input
          v-model="$v.name.$model"
          :class="$v.name.$error && 'is-invalid'"
          trim
        >
          <b-form-invalid-feedback v-if="!$v.name.required">
            {{ $t("auth.type-valid-name") }}
          </b-form-invalid-feedback>
        </b-form-input>
      </b-form-group>
      <b-form-group label="Descrição">
        <b-form-textarea
          v-model="$v.description.$model"
          :class="$v.description.$error && 'is-invalid'"
          trim
        ></b-form-textarea>
        <b-form-invalid-feedback v-if="!$v.description.required">
          {{ $t("auth.type-valid-email") }}
        </b-form-invalid-feedback>
      </b-form-group>
      <div class="d-flex justify-content-end">
        <b-button variant="link" @click="$emit('submited')">Cancelar</b-button>
        <b-button
          type="submit"
          variant="primary"
          :disabled="isLoading"
          class="pull-right text-right"
        >
          <b-spinner v-if="isLoading" small class="mr-2" />
          <span> {{ $t("general.save") }}</span>
        </b-button>
      </div>
    </b-form>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
import { mapActions } from "vuex";

export default {
  mixins: [validationMixin],
  props: {
    id: {
      type: [String, Number],
    },
    isAdd: {
      type: Boolean,
    },
  },
  data() {
    return {
      isLoading: false,
      name: null,
      description: null,
      admin: false,
    };
  },
  validations: {
    name: {
      required,
      minLength: minLength(4),
    },
    description: {
      required,
    },
  },
  methods: {
    ...mapActions(["add_form", "get_form", "show_toast", "edit_form"]),
    handleSubmitForm() {
      this.isLoading = true;
      if (!this.$v.$anyError && !this.$v.$anyError) {
        this.$v.$touch();
        if (this.isAdd) {
          this.saveFormQuestion();
        } else {
          this.editformQuestion();
        }
      }
    },
    saveFormQuestion() {
      this.add_form({
        name: this.name,
        description: this.description,
      })
        .then(({ status }) => {
          if ([200, 201].includes(status)) {
            this.show_toast({
              message: "Cadastro realizado com sucesso",
              type: "success",
            });
            this.get_form({ id: this.id });
            this.$emit("submited");
          }
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          this.show_toast({
            message: err,
            type: "error",
          });
        });
    },
    editformQuestion() {
      this.edit_form({
        name: this.name,
        description: this.description,
        id: this.id,
      })
        .then(({ status }) => {
          if ([200, 201].includes(status)) {
            this.show_toast({
              message: "Edição realizada com sucesso",
              type: "success",
            });
            this.get_form({ id: this.id });
            this.$emit("submited");
          }
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          this.show_toast({
            message: err,
            type: "error",
          });
        });
    },
    getEdit() {
      this.get_form({ id: this.id }).then(({ data: { name, description } }) => {
        this.name = name;
        this.description = description;
      });
    },
  },

  mounted() {
    if (!this.isAdd) {
      this.getEdit();
    }
  },
};
</script>
